body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --fjd-font-brand: Arial !important;
  --fjd-font-headline: Oswald, sans-serif;
  font-family: var(--fjd-font-family);
}

.center-content-horizontally {
  width: 50%;
  margin: auto;
}

.fjd-hero-image:before {
  background-color: rgba(0, 0, 0, 0) !important;
}

.fjd-hero-image__heading > * {
  font-family: var(--fjd-font-headline) !important;
}

.fjd-header__logo * {
  font-family: var(--fjd-font-headline) !important;
}

.fjd-modal__header {
  margin: var(--fjd-spacing-xl) var(--fjd-spacing-xl) 0 var(--fjd-spacing-xl) !important;
}

.fjd-modal__body {
  margin: var(--fjd-spacing-xl) !important;
  white-space: pre-line;
}

.fjd-button-select-group {
  flex-wrap: nowrap !important;
}

.fjd-description-list-item__description {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fjd-tooltip {
  padding: 0 var(--fjd-spacing-xs);
  align-items: center;
}

.fjd-modal__content--width-wide {
  height: 100%;
}

.dropdown-content {
  width: unset !important;
}

.rmsc .options {
  max-height: 100% !important;
}

.fjd-form-control__inputs {
  display: flex !important;
  gap: var(--fjd-spacing-m);
}

.fjd-select {
  background-color: white !important;
}

.fjd-text-input {
  background-color: white !important;
}

/* ####### TABLE ####### */

.fjd-table__tbody {
  border: 1px solid #dfdfdf;
}

.fjd-table__th {
  color: #4a4a4a !important;
  vertical-align: middle !important;
}

.fjd-table__column-filter {
  min-width: 0 !important;
}

.fjd-table__th:last-child {
  width: 2rem !important;
}

/* ####### HERO ####### */
.fjd-hero-image__heading > h1 {
  font-size: 40px !important;
  font-weight: var(--fjd-font-weight-bold) !important;
  max-width: 560px;
}

.fjd-hero-image__heading > h4 {
  font-size: var(--fjd-font-size-xl) !important;
  font-weight: var(--fjd-font-weight-regular) !important;
  max-width: 560px;
  line-height: var(--fjd-line-height-2) !important;
}

/* ####### NAVBAR ####### */
.fjd-navigation-bar {
  min-height: 48px;
}

/* ####### FILE PREVIEW ####### */
.fjd-file-preview {
  width: 100%;
}

/* ####### FLYOUT ####### */
.fjd-flyout__inner {
  width: inherit !important;
}

.fjd-flyout__inner > div {
  width: 100% !important;
}

.fjd-flyout > div {
  width: 90% !important;
}

ul ul {
  margin-left: var(--fjd-spacing-m);
}
