.horizontal-flex-container {
  display: flex;
  flex-direction: row;
}

/* temp fix for mobile view */
.listitem-button-mobile {
  margin-left: -14px;
}

.result-list-border-4Sides {
  border: 0.001rem solid rgba(167, 167, 167, 0.5);
  padding: 10px 0 10px 16px;
}

.result-list-disabled {
  background-color: rgba(189, 192, 190, 0.1);
  color: var(--fjd-color-n700) !important;
}

.result-list-rows {
  display: flex;
}

.result-list-flex {
  display: flex;
  padding-right: 20px;
}

.online-list-item__subline-item {
  font-size: var(--fjd-font-size-xs);
  margin: 0 !important;
}
