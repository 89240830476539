.chooseOperator {
  display: flex;
  flex-direction: row;
  gap: var(--fjd-spacing-xs);
  justify-content: space-between;
  padding: var(--fjd-spacing-xs);
  border: 2px solid var(--fjd-color-input-border);
  border-radius: var(--fjd-border-radius-xs);
  box-shadow: var(--fjd-box-shadow-m);
}

.chooseOperator > div {
  width: 100% !important;
}

.chooseOperator > div > span {
  width: 100% !important;
  display: flex;
  justify-content: center;
}
