.step-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  max-width: 320px;
  align-items: center;
}

.step-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}

.heading {
  width: 240px;
}
