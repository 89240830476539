.model-description {
  border: 0.001rem solid var(--fjd-color-input-border);
  border-radius: var(--fjd-border-radius-m);
  padding: var(--fjd-spacing-l);
  text-align: center;
}

.service-not-available {
  border: 0.001rem solid var(--fjd-color-input-border);
  background-color: var(--fjd-color-background-alternate);
  border-radius: var(--fjd-border-radius-m);
  padding: var(--fjd-spacing-xxl);
  display: flex;
  justify-content: space-between;
}

.model-condition {
  margin: 0 !important;
}

.header-description {
  padding-right: var(--fjd-spacing-3xl);
}

.model-variant > div {
  line-height: var(--fjd-spacing-xl);
  color: var(--fjd-color-input-placeholder);
}

.model-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
}
