.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.tab {
  padding: 0 var(--fjd-spacing-s) 0 0;
}

.radio-toolbar label {
  display: inline-block;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border-radius: 0;
  margin-right: var(--fjd-spacing-l);
  padding: var(--fjd-spacing-s) 0;
  border-bottom: 2px solid transparent;
  font-weight: var(--fjd-font-weight-medium);
}

.radio-toolbar label:hover {
  border-bottom: 2px solid var(--fjd-color-action-primary);
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: transparent;
  border-bottom: 2px solid var(--fjd-color-action-primary);
  color: var(--fjd-color-action-primary);
}
