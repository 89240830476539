.hint {
  border: 0.001rem solid var(--fjd-color-action-primary);
  border-radius: var(--fjd-border-radius-m);
  padding: var(--fjd-spacing-xxl);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.radio-toolbar {
  border-bottom: solid var(--fjd-color-n600);
}

.radio-toolbar input[type="radio"]:checked + label {
  margin-bottom: -3px;
  border-bottom: 4px solid var(--fjd-color-action-primary) !important;
}
