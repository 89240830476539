.footer {
  flex-direction: column;
}

.footer-copyright {
  color: var(--fjd-color-action-secondary);
  font-weight: var(--fjd-font-weight-medium);
  font-size: var(--fjd-font-size-xxs);
  background-color: var(--fjd-color-background-alternate);
  padding-bottom: var(--fjd-spacing-m);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-hint {
  font-size: var(--fjd-font-size-s);
  background-color: var(--fjd-color-n200);
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  border-bottom: 1px solid var(--fjd-color-n600);
}

.footer-hint-mobile {
  display: inline-block;
  text-align: center;
  padding: var(--fjd-spacing-s) var(--fjd-spacing-m);
}

.footer-hint-desktop {
  display: flex;
  white-space: pre;
  padding: var(--fjd-spacing-s) 0;
}

.footer-hint-link {
  padding-left: var(--fjd-spacing-xs);
  color: var(--fjd-color-action-primary);
  font-family: var(--fjd-font-family);
  font-size: var(--fjd-font-size-s);
  font-weight: var(--fjd-font-weight-regular);
  line-height: var(--fjd-line-height);
}
