.chooseOperatorButton > Button > span {
  display: flex;
  justify-content: space-between;
}

.chooseOperator > div > span > Button {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.chooseOperatorButton > Button {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.placeholder {
  height: 250px;
}
