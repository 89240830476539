.layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.layout-inner {
  width: 100vw;
  max-width: 1140px;
  margin: 0 var(--fjd-spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.layout-content {
  padding: 0 var(--fjd-spacing-l) var(--fjd-spacing-5xl) var(--fjd-spacing-l);
  display: flex;
  flex-direction: column;
}
