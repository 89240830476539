.call-to-action {
  border: 0.001rem solid var(--fjd-color-input-border);
  background-color: var(--fjd-color-background-alternate);
  border-radius: var(--fjd-border-radius-m);
  padding: var(--fjd-spacing-xxl);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.call-to-action-button-alignemnt {
  width: 70%;
}

@media (max-width: 1024px) {
  .call-to-action-button-alignemnt {
    width: 100%;
    margin-bottom: var(--fjd-spacing-m) !important;
    margin-right: var(--fjd-spacing-s);
  }
}
