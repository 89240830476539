.marketplace-container {
  padding: var(--fjd-spacing-l);
  display: flex;
  flex-direction: column;
}

.search-headline {
  margin-bottom: 0 !important;
}

.card-container {
  align-items: unset !important;
}

.card-footer-container {
  justify-content: space-between !important;
}

.service-radio-group {
  padding: var(--fjd-spacing-s) 0;
}

.load-button-container {
  padding-top: var(--fjd-spacing-l);
  display: flex;
  justify-content: center;
}

.tooltipped-item > * {
  margin-right: 0 !important;
}
