.search-filter-bar-wrapper {
  top: 0;
  padding: var(--fjd-spacing-s) 0 var(--fjd-spacing-s) 0;
  background-color: var(--fjd-color-background);
  padding-bottom: 0 !important;
}

.search-filter-bar-wrapper-sticky {
  position: sticky;
  z-index: 1000;
}

.search-filter-bar-wrapper-relative {
  position: relative;
}

.search-filter-bar {
  display: flex;
  flex-direction: row;
  gap: var(--fjd-spacing-xs);
  justify-content: space-between;
  padding: var(--fjd-spacing-xs);
  border: 2px solid var(--fjd-color-input-border);
  border-radius: var(--fjd-border-radius-xs);
  box-shadow: var(--fjd-box-shadow-m);
}

.search-filter-bar:focus-within {
  border: 2px solid var(--fjd-color-action-primary);
}

.search-filter-bar-icon {
  background-image: var(--fjd-svg-search);
  background-position: left var(--fjd-spacing-s) center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  padding-left: calc(1.25rem + var(--fjd-spacing-s) * 2);
}

.search-input {
  min-width: 40%;
  border: 0;
  height: 32px;
  flex-grow: 1;
}

.search-input:focus {
  border: 0;
  outline: 0;
}

.clear-all {
  width: 32px;
  border: 0;
  outline: 0;
  background-color: rgba(255, 255, 255, 50);
}

.clear-all:hover {
  background-color: rgba(200, 200, 200, 50);
}

.search-result:hover {
  background-color: rgba(200, 200, 200, 50);
  border-radius: var(--fjd-border-radius-xs);
}

.search-result {
  padding: var(--fjd-spacing-xs) var(--fjd-spacing-s);
}

.search-menu {
  display: flex;
  max-height: 230px;
  overflow-y: auto;
  width: 100%;
  z-index: 10000;
  position: absolute;
  background: white;
  flex-direction: column;
  gap: var(--fjd-spacing-s);
  padding: var(--fjd-spacing-s);
  border-radius: var(--fjd-border-radius-s);
  box-shadow: 0 0 0 1px rgb(53 72 91 / 40%);
  margin-top: 2px;
}

.tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--fjd-spacing-xs);
  width: 100%;
}

.search {
  gap: var(--fjd-spacing-xs);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.search-filter-bar::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, #ffffff 10%, #ffffff00 100%);
  transform: translate3d(0px, 3.5rem, 0) scale(100%);
  height: 40px;
}

/*
* Prevents issues when the parent creates a
* stacking context. (For example, using the transform
* property )
*/
.search-filter-bar::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  transform: translate3d(0px, -2px, 0) scale(100%);
}
